const IconLink = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3335 10.834C8.69138 11.3124 9.14796 11.7083 9.67229 11.9947C10.1966 12.2812 10.7764 12.4516 11.3724 12.4942C11.9683 12.5369 12.5665 12.4509 13.1263 12.2421C13.6861 12.0333 14.1944 11.7065 14.6168 11.284L17.1168 8.78396C17.8758 7.99811 18.2958 6.9456 18.2863 5.85312C18.2768 4.76063 17.8386 3.71558 17.0661 2.94304C16.2935 2.17051 15.2485 1.73231 14.156 1.72281C13.0635 1.71332 12.011 2.1333 11.2252 2.89229L9.79183 4.31729M11.6668 9.16729C11.309 8.68885 10.8524 8.29297 10.328 8.00651C9.80371 7.72004 9.22391 7.54969 8.62796 7.50701C8.032 7.46433 7.43384 7.55032 6.87405 7.75914C6.31425 7.96796 5.8059 8.29473 5.3835 8.71729L2.8835 11.2173C2.12451 12.0031 1.70453 13.0556 1.71402 14.1481C1.72352 15.2406 2.16172 16.2857 2.93426 17.0582C3.70679 17.8307 4.75184 18.2689 5.84433 18.2784C6.93681 18.2879 7.98932 17.8679 8.77517 17.109L10.2002 15.684"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLink
